<template>
  <div>
    <b-card class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <span class="align-middle ml-50">جدول القاعة</span>
        </b-card-title>
      </b-card-header>
      <data-table
        striped
        responsive
        class="mb-0"
        :fields="fields"
        :pagination="false"
        :ep="`hall-appointments/${id}`"
      >
        <template #cell(module)>
          <div>ورشة العمل التنموية الخاصة</div>
        </template>
      </data-table>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'halls-occupancy' })"
        >
          إشغال قاعة
        </b-button>
      </div>
    </b-card>
  </div>
</template>
  
  <script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue";
export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    DataTable,
  },
  props: ["id"],
  data: () => {
    return {
      fields: [
        { key: "module", label: "اسم النشاط",sortable:true },
        { key: "from_time", label: "من" },
        { key: "to_time", label: "إلى" },
      ],
    };
  },
  methods: {
    ...mapActions("manageCenter/hallsServices", [""]),
  },
  computed: {
    ...mapGetters("manageCenter/hallsServices", ["hallDetails", "loading"]),
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  